import { template as template_6a86b25ada754b9e892ff17d87a69147 } from "@ember/template-compiler";
const FKLabel = template_6a86b25ada754b9e892ff17d87a69147(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
