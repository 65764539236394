import { template as template_84e5c6812a0c4e8f97a91676bf936aff } from "@ember/template-compiler";
const FKControlMenuContainer = template_84e5c6812a0c4e8f97a91676bf936aff(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
