import { template as template_9083938354ff4d03a9a8a91fe44ff941 } from "@ember/template-compiler";
import { gt } from "truth-helpers";
import number from "discourse/helpers/number";
import icon from "discourse-common/helpers/d-icon";
const OpLikesCell = template_9083938354ff4d03a9a8a91fe44ff941(`
  <td class="num likes">
    {{#if (gt @topic.op_like_count 0)}}
      <a href={{@topic.summaryUrl}}>
        {{number @topic.op_like_count}}
        {{icon "heart"}}
      </a>
    {{/if}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
