import { template as template_4de6530a28ad4fc08ae9a27a2416815e } from "@ember/template-compiler";
const FKText = template_4de6530a28ad4fc08ae9a27a2416815e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
